import React from 'react';

import { LoadableComponentProps } from '@utils/LoadableComponentProps';
import { Divider } from '~/ui/kit/atoms/Divider';
import { Skeleton } from '~/ui/kit/atoms/Skeleton';
import { StringLink } from '~/ui/kit/molecules/StringLink';

import { HOLDING_TYPE, HoldingType } from '../../Holding.entity.types';
import * as S from './HoldingInfo.styled';

type DataProps = {
  name: string;
  type: HoldingType;
  industry: string;
  socialTopics?: string[];
  websiteLink?: string;
  issuer?: string | null;
  location?: string;
};

type Props = {
  className?: string;
  socialTopics?: string[];
  websiteLink?: string;
  location?: string;
} & LoadableComponentProps<DataProps>;

const Template: React.FC<{
  type: React.ReactNode;
  industry: React.ReactNode;
  topics?: string[];
  link: React.ReactNode;
  location?: React.ReactNode;
  issuer?: string | null;
}> = (props) => (
  <>
    <S.Section>
      <S.Line $isBold>Type:</S.Line>
      <S.Line>{props.type}</S.Line>
    </S.Section>
    <S.Section>
      <S.Line $isBold>Industry:</S.Line>
      <S.Line>{props.industry}</S.Line>
    </S.Section>
    {props.topics && props.topics.length > 0 && (
      <S.Section>
        <S.Line $isBold>Social Topic{props.topics.length > 1 ? 's' : ''}:</S.Line>
        <S.Line>{props.topics.join(', ')}</S.Line>
      </S.Section>
    )}
    {props.issuer && (
      <S.Section>
        <S.Line $isBold>Issuer:</S.Line>
        <S.Line>{props.issuer}</S.Line>
      </S.Section>
    )}
    {(props.location || props.link) && (
      <>
        <Divider />
        <S.Section>{props.location && <S.Line>{props.location}</S.Line>}</S.Section>
        <S.Section>{props.link && <S.Line>{props.link}</S.Line>}</S.Section>
      </>
    )}
  </>
);
const LineSkeleton = <Skeleton.LineSkeleton />;

const loadingProps = {
  type: LineSkeleton,
  industry: LineSkeleton,
  topic: LineSkeleton,
  location: LineSkeleton,
  link: LineSkeleton,
};

export const HoldingInfo: React.FC<Props> = (props) => {
  const templateProps = props.isLoading
    ? loadingProps
    : {
        type: props.type,
        industry: props.industry,
        topics: props.socialTopics,
        issuer: props.issuer,
        link: props.websiteLink ? <StringLink stringUrl={props.websiteLink} /> : undefined,
        location: props.location ? (
          <S.Location>
            <S.LocationIcon />
            <div>{props.location}</div>
          </S.Location>
        ) : undefined,
      };

  const isETF = props.isLoading ? false : props.type === HOLDING_TYPE.ETF;

  return (
    <S.CardSection className={props.className}>
      <S.Container $isETF={isETF}>
        <Template {...templateProps} />
      </S.Container>
    </S.CardSection>
  );
};
