import { AssetDTO, AssetEntity } from '../../asset.types';
import { mapAssetType } from '../mapAssetType';

import { mapNumberDto } from '~/shared/dtoMappers';
import { mapLocationToString } from '@utils/location/mapLocationToString';

export const mapAssetDtoToDomain = (dto: AssetDTO): AssetEntity => {
  return {
    id: dto.ticker,
    ticker: dto.ticker,
    name: dto.name,
    type: mapAssetType(dto.type),
    industry: dto.industry,
    issuer: dto.issuer,
    socialTopics: dto.socialTopics || [],
    location: mapLocationToString({
      country: dto.country,
      state: dto.state,
      city: dto.city,
    }),
    websiteLink: dto.url,
    description: dto.description,
    price: mapNumberDto(dto.last_price),
  };
};
